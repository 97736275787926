import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import SanityBlockContent from '@sanity/block-content-to-react';

import { motion } from 'framer-motion';
import { Header } from '../components/Header';
import { serializers } from '../utils/serializer';
import { TextSection } from '../components/TextSection';
import { container } from '../utils/framer-motion-config';

export default function PrivacyPolicyPage({ data }) {
  const sanityContent = data.privacypage.contentSections;

  const LastPartOfSanityContent = sanityContent.slice(5);

  return (
    <PrivacyPolicyPageContainer>
      <PrivacyPolicyPageContent
        variants={container}
        initial="hidden"
        animate="show"
      >
        <TextSection>
          <Header big bolder>
            {sanityContent[0].title}
          </Header>
          <SanityBlockContent
            blocks={sanityContent[0]._rawText}
            serializers={serializers}
            projectId={process.env.SANITY_PROJECT_ID}
            dataset={process.env.SANITY_DATASET}
          />
        </TextSection>

        <Header big bolder>
          {sanityContent[1].title}
        </Header>
        <TextSection>
          <Header>{sanityContent[1].subtitle}</Header>
          <SanityBlockContent
            blocks={sanityContent[1]._rawText}
            serializers={serializers}
            projectId={process.env.SANITY_PROJECT_ID}
            dataset={process.env.SANITY_DATASET}
          />
        </TextSection>

        <TextSection>
          <Header>{sanityContent[2].title}</Header>
          <SanityBlockContent
            blocks={sanityContent[2]._rawText}
            serializers={serializers}
            projectId={process.env.SANITY_PROJECT_ID}
            dataset={process.env.SANITY_DATASET}
          />
        </TextSection>

        <TextSection>
          <Header>{sanityContent[3].title}</Header>
          <SanityBlockContent
            blocks={sanityContent[3]._rawText}
            serializers={serializers}
            projectId={process.env.SANITY_PROJECT_ID}
            dataset={process.env.SANITY_DATASET}
          />
        </TextSection>

        <Header big bolder>
          {sanityContent[4].title}
        </Header>
        <TextSection>
          <Header>{sanityContent[4].subtitle}</Header>
          <SanityBlockContent
            blocks={sanityContent[4]._rawText}
            serializers={serializers}
            projectId={process.env.SANITY_PROJECT_ID}
            dataset={process.env.SANITY_DATASET}
          />
        </TextSection>

        {LastPartOfSanityContent.map((content, index) => (
          <TextSection key={index}>
            <Header>{content.title}</Header>
            <SanityBlockContent
              blocks={content._rawText}
              serializers={serializers}
              projectId={process.env.SANITY_PROJECT_ID}
              dataset={process.env.SANITY_DATASET}
            />
          </TextSection>
        ))}
      </PrivacyPolicyPageContent>
    </PrivacyPolicyPageContainer>
  );
}

export const query = graphql`
  query PrivacyQuery {
    privacypage: sanityPage(
      id: { eq: "-5d88410c-f352-55f9-a57a-7a807daf313f" }
    ) {
      contentSections {
        ... on SanityTextSection {
          title
          subtitle
          _rawText
        }
      }
    }
  }
`;

const PrivacyPolicyPageContainer = styled.div``;

const PrivacyPolicyPageContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 3rem;
  position: relative;
  flex: 1;
  transition: 0.2s ease-in-out;

  @media (min-width: 1080px) {
    width: 100%;
    max-width: var(--gridWidth);
    margin: 0 auto;
    padding: 8.6rem 0 2rem 0;
  }
`;
